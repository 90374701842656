.not-found-sec.main-bg {
  background-color: #E5ECF8;
  height: 100vh;
  width: 100%;   
}
.not-found-sec-logo{
  display: flex;
  justify-content: center;
}
.not-found-sec-logo img{
  position: relative;
  top: 47px;
  height: 100px;
  width: 204px;
}
.not-found-sec-content{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: 39%;
  left: 50%;
  transform: translate(-50%,-50%);
  
}

.not-found-sec-content .content404{
  font-weight: 900;
  color: #003282;
  font-size: 150px;   
}
.not-found-sec-content .content-oops{
  color: #1E1E1E;
  font-size: 35px;
}