
.spinner {
  margin: 20px auto;
  width: 500px;
  height: 450px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative
}

.spinner .circle {
  animation: spinners linear infinite;
  position: absolute;
  border: 5px solid transparent;
  border-top-color: #F00;
  border-radius: 50%
}

.spinner .one {
  width: 50px;
  height: 50px;
  border-top-color: #4F81BD;
  animation-duration: .85s
}

.spinner .two {
  width: 70px;
  height: 70px;
  border-top-color: #2f88d1;
  animation-duration: .95s
}

.spinner .three {
  width: 100px;
  height: 100px;
  border-top-color: #3976c5;
  animation-duration: 1.05s
}


@keyframes spinners {
  0% {
      transform: rotate(0deg)
  }
  
  100% {
      transform: rotate(360deg)
  }
}